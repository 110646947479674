import * as React from "react";
import { Box, Button, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";
import { log } from "console";
import Logo from "../../img/logo.png";
import LogoSvg from "../../img/logo.svg";

export default function Header() {
  return (
    <>
      <Flex
        h={79}
        w={"100%"}
        bg={"#f5f5f5"}
        direction={"row"}
        align={"center"}
        justifyContent={"space-between"}
        px={"10%"}
        // bg={"black"}
      >
        <Image src={LogoSvg} alt="logo" h={"90%"}></Image>
        <Link href="https://santagema.com.br/web">
          <Button
            color={"black"}
            border={"black solid 1px"}
            _focus={{
              border: "0px",
              backgroundColor: "black",
              color: "#f5f5f5",
            }}
            //   _hover={{
            //     color: "white",
            //   }}
          >
            Dashboard
          </Button>
        </Link>
      </Flex>
    </>
  );
}
