import * as React from "react";
import { Box, Button, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { log } from "console";
import Header from "../componentes/commons/header";
import Gif from "../img/galinhagif.gif";

export default function Index() {
  return (
    <>
      <Flex
        w={"100vw"}
        h={"100vh"}
        bg={"white"}
        direction={"column"}
        align={"center"}
      >
        <Header></Header>

        <Stack flex={1} align={"center"} justifyContent={"center"}>
          <Image src={Gif} alt="logo" objectFit="contain" h={"40%"}></Image>
          <Text fontSize={40}>Em construção ...</Text>
        </Stack>
      </Flex>
    </>
  );
}
